@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "node_modules/sweetalert/dev/sweetalert";
@import "node_modules/dropzone/src/dropzone";

// customised boostrap stuff


$brand-primary:         #3251a1;  //dark blue from logo
$brand-success:         #077595;  //Teal
$brand-info:            #AF987C;  //greyish earthy colour
$brand-warning:         #F18636;  //orange logo
$brand-danger:          #1b144f;  //dark grey/navy

// rest of less stuff ----------------------->

hr{
  border-top-color: $brand-warning;
}

//basic font styling, sizes fonts etc ----------------------->

body{
  padding-top: 120px;
  font-family: 'liberation_sansregular', sans-serif;
  font-size: 12pt;
  color: #333;
  // background-color: lighten($brand-warning,40%);
}

h1,h2,h3{
  font-family: 'League Gothic';
}


h1{
  font-size: 20pt;
  color: $brand-primary;
}

h2{
  font-size:18pt;
  color: $brand-danger;
}

h3{
  font-size:16pt;
  color: $brand-success;
}

em{
  color: $brand-warning;
  font-style: normal;
}

.underline {
    border-bottom:1px solid $brand-warning;
}

.right-align{
  text-align: right;
}

.center-align{
  text-align: center;
}

// page layout stuff ----------------------->

// main row wrapper
.wrapper {
  @include make-row();
}
//----------------------------------------->
// 2 column layout with main and sidebar

.content-main {
  @include make-sm-column(8);
}

.content-aside {
  @include make-sm-column(4);
}

.content-full {
  @include make-sm-column(12);
}

//---------------------------------------->

// 3 column split equal

.content-thirds {
  @include make-sm-column(4);
}

//----------------------------------------->

.half{
  @include make-sm-column(6);
}

// // make all images responsive as default ----------------------->

img{
  @extend .img-responsive;
}

// Nav bar tweaks ------------------------------------------------>


// uncomment to make dropdowns display on hover
// ul.nav li.dropdown:hover > ul.dropdown-menu {
//     display: block;
// }

.navbar {
  border-bottom:3px solid $brand-warning;
  border-top: 3px solid $brand-warning;
  padding-right: 30px;
  padding-left: 30px;
  font-size: 10pt;
}

.nav-logo {
  img {
    max-height:100px;
    padding: 0;
    margin:0;
  }
}

// .navbar-collapse{
//   border-top: 3px solid $brand-warning;
// }

hr {
  color: $brand-warning;
}

// Splash Screen Styling

.img-splash{
	max-width: 100%;
  	width:100%;
	border-radius: 3px;
}

h2.splash{
  font-size: 26pt;
}

// address list styling

ul.navbar-right{
  color: $brand-primary;
  li{
    float: none;
    padding: 0;
    margin: 0;
    list-style: none;
  }
  h1 {
    padding-top: 15px;
    font-size: 28pt;
    margin:0;
  }
  h2, h3 {
    margin:0;
  }
  p {
    margin:0;
  }
  // margin-bottom: 10px;
}

// colours Page

ul.no-style {
  list-style: none;
}

.underline {
  margin-top: 15px;
}

img.colours {
  width: 100%;
}

//owl carousel

.frontBanner{
  margin-top:1em;
}

#owl-splash .item img{
    display: block;
    width: 100%;
    height: auto;
}

.glyphicon{
  color: $brand-primary;
}

// gallery page
// 
.galItem{
  margin:auto;
  text-align: center;
}